$color-variable-example: crimson;
$text-cariable-example: "All Hail The Crimson King";

//$main-font: "IBM Plex Sans", Helvetica, Arial, sans-serif;
$main-font: "Verdana", Arial, sans-serif;

$accent-color-banners: #001D3D;
$accent-choise-card: #008A5E;
$button-main-color: #4294E0;
$button-accent-banner: #F9F871;
$white-color: #fff;

//colors
$main-color: #171219;
$black: #171219;
$gray: #7F7F7F;
$grey-light: #9F9F9F;
$primary-color: $button-main-color;
$blue-color: #97c9e8;
$decorative-color: $accent-choise-card;
$panel-item-color: #fff;

$wrapper-bg: #F0FAFF;
$promo-bg: transparent;
$promo-text: $black;
$promo-link: #fff;
$promo-circle: $button-main-color;
$mirror-link-color: #97c9e8;
$product-flag: $blue-color;

$post-category-color: $button-main-color;
$post-category-color-hover: white;
$post-arrow-color: $button-main-color;
$post-arrow-color-hover: #FFFFFF;
$post-title: $black;
$post-title-hover: $white-color;
$post-wrapper-bg-active: $accent-color-banners;

$product-text-color: #171219;

$btn-text-transform: uppercase;
$btn-bd-primary: 2px solid $primary-color;
$btn-bd-secondary: 1px solid #97C9E8;
$primary-button-color: $primary-color;
$primary-button-color-hover: #3C86C9;
$primary-button-border-color-hover: $primary-color;

$secondary-button-color: $button-accent-banner;
$secondary-button-color-hover: #F0EF7C;
$secondary-text-color: $accent-color-banners;

$active-color: $button-main-color;
$secondary-active-color: #97C9E8;
$image-bg: $button-main-color;
$step-bio-color: $button-main-color;
$post-img-color: $button-main-color;
$post-wrapper-bg-hover: $accent-color-banners;
$product-banner-color: $button-main-color;
$post-wrapper-bg: #FFFFFF;
$post-item-radius: 16px;

$steps-bio-radius: 16px;
$interactive-img-radius: 20px;
$interactive-bg-color: #fff;
$interactive-right-bg: transparent;
$circle-color: $button-main-color;
$btn-bd-radius: 32px;
$header-border-bottom: none;
$interactive-text-align: left;
$links-color: $black;
$links-weight: 400;
$pages-link-width: 200px;
$quiz-link-btn-padding: 18px 29px;
$breadcrumbs-icon: '../img/play-arrow-blue.svg';
$checked-list-justify: flex-start;
$quiz-b-radius: 16px;
$quiz-shadow: 0px 4px 16px rgba(23, 18, 25, 0.10);
$secondary-btn-posX-mob: 0;

$choice-bg: '../img/popular-choice-green.webp';
$popular-item-shadow: 0px 0px 3px 0px rgba(0, 138, 94, 0.98), 0px 0px 6px 0px rgba(0, 138, 94, 0.85), 0px 0px 8px 0px rgba(0, 138, 94, 0.50), 0px 0px 9px 0px rgba(0, 138, 94, 0.15), 0px 0px 10px 0px rgba(0, 138, 94, 0.02);
$catalog-hint-color: #008A5E;
$catalog-hint-bg: rgba(#008A5E, .2);
$catalog-hint-icon: "../img/popular-rate-green.svg";
$rating-bg: '../img/rate-bg-yellow.svg';
$advantages-color: $button-main-color;
$footer-bg: #FFFFFF;

$promo-product-bg-slavic: "../img/promo-bg-slavic-2.webp";
$promo-product-bg-slavic-m: "../img/promo-bg-slavic-mob-2.webp";
$promo-product-bg-asian: "../img/promo-bg-asian-2.webp";
$promo-product-bg-asian-m: "../img/promo-bg-asian-mob-2.webp";
$promo-product-bg-latin: "../img/promo-bg-latin-2.webp";
$promo-product-bg-latin-m: "../img/promo-bg-latin-mob-2.webp";
$promo-btn-bg: $secondary-button-color;
$promo-btn-color: $secondary-text-color;
$promo-btn-bg-hover: $secondary-button-color-hover;
$promo-btn-color-hover: inherit;

$stats-bg: #FFFFFF;
$nav-bg: $wrapper-bg;
$bio-shadow-img: "../img/bio-shadow.png";
$quiz-shadow-img: "../img/quiz-shadow.png";
$faq-bg: #F2F6F6;
$faq-shadow: 0px 4px 16px 0px rgba(23, 18, 25, 0.10);
$faq-b-radius: 16px;
$faq-color: #171219;
$catalog-counter-bg: '../img/counter-bg.svg';
$quiz-img-b-color: #fff;

@mixin header {}

@mixin footer {

    footer .links__link {

        @include rm(768) {
            text-align: left;
        }
    }

    footer .links {
        margin-left: 0;
    }

    footer .links__title_footer {
        color: #51225D;

        @include rm(768) {
            font-size: 16px;
            text-align: left;
        }
    }

    footer .links__item:last-child {
        display: none;
    }
}

@mixin posts {}

@mixin links {
    .links {
        @include rm(640) {
            flex-direction: column;
        }

        @include rm(768) {
            flex-direction: row;
            margin: 0 25px 0 0;
        }
    }
}

@mixin navigation {
    .links {
        &__title_header {
            @include rm(768) {
                display: none;
            }
        }

        &_mob {
            display: none;
        }
    }

    .nav.active {
        border-top: 1px solid rgba(127, 127, 127, 0.50);
        margin-top: 6px;

        .links_mob {
            display: flex;
        }
    }
}

@mixin pages-links {
    .pages__link {
        @include rm(768) {
            text-align: right;
        }
    }

    .pages__wrapper {
        display: none;
        @include rm(768) {
            margin-right: auto;
            flex-shrink: 0;
        }
    }

    .logo__link {
        margin-right: 25px;
    }
}

@mixin quiz-link {
    .quiz-link {
        @include rm(1024) {
            margin-left: auto;
        }
    }

    .quiz-link__mob {
        display: block;
        margin-bottom: 22px;

        @include rm(768) {
            display: none;
        }
    }
}

@mixin blog {

    .blog__aside .post__link {
        margin-left: auto;
    }

    .steps__bio {
        overflow: hidden;

        &_decorate {
            position: relative;

            &:after {
                position: absolute;
                width: 50%;
                height: 100%;
                right: 0;
                bottom: 0;
                content: '';
                background: bottom right/contain no-repeat url("../img/bio-decor-asian.webp");
            }
        }
    }
}

@mixin breadcrumbs {
    .breadcrumbs .container {
        max-width: 1040px;
    }
}

@mixin checked {
    .checked {
        @include rm(640) {
            padding-top: 16px;
            padding-bottom: 0;
        }
    }
}

@mixin quiz {
    .interactive {

        &__img {
            border: none;
        }

        &:after {
            z-index: 3;
            position: absolute;
            content: "🔎 Find the Platform";
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            padding-bottom: 14px;
            text-align: center;
            line-height: 1.2;
            font-size: 16px;
            background: bottom left/contain no-repeat url($choice-bg);
            width: 198px;
            height: 54px;
        }
    }
}

@mixin product {}

@mixin faq {}

@mixin catalog {}

@mixin promo {}

@mixin text-tooltip {}
