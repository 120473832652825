.catalog {
  padding-top: 20px;
  padding-bottom: 40px;
}

.update-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: #171219;
  font-size: 16px;
  font-weight: 400;

  &__img {
    margin-right: 8px;

    & path {
      stroke: $decorative-color;
    }
  }

  @include rm(1024) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @include rm(768) {
    justify-content: flex-end;
    margin-bottom: 12px;
  }
}

.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  list-style-type: none;
  counter-reset: section;
  gap: 15px;

  @include rm(640){
    align-items: stretch;
  }

  @include rm(1024){
    gap: 20px;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.catalog-item__title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  text-align: center;
  margin-bottom: 15px;
  width: calc(100% - 38px);
  margin-left: 40px;
  
  @include rm(1024){
    margin-right: 18px;
    align-self: flex-end;
    max-width: 260px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
    max-width: 323px;
    height: auto;
    align-items: flex-start;
    margin-left: 0;
  }
}

.catalog-item__rating {
  text-align: center;
  font-size: 26px;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.catalog-item__rating .text-bold {
  background: center/contain no-repeat url($rating-bg);
  width: 90px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;
}

.catalog-item__rating--mob {
  @include rm(1024){
    display: none;
  }
}
.catalog-item__rating--desk {
  display: none;
  @include rm(1024){
    display: flex;
  }
}

.catalog-item__advanteges {
  position: relative;
  font-size: 14px;
  padding-left: 15px;
  color: $black;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $advantages-color;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.catalog-item__more {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: $primary-button-color;
  margin-top: 15px;
  margin-bottom: 12px;

  @include rm(1024){
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.catalog-item {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  padding: 25px 20px;
  position: relative;
  counter-increment: section;
  background: $panel-item-color;
  box-shadow: 0 4px 16px rgba(23, 18, 25, 0.1);
  border-radius: 16px;

  &__bottom {
    margin-top: auto;

    @include rm(1024) {
      margin-top: unset;
    }
  }

  &:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: counter(section) '';
    width: 38px;
    height: 38px;
    color: white;
    z-index: 2;
    background-image: url($catalog-counter-bg);
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    left: 30px;
    top: 26px;

    @include rm(768){
      left: 50px;
    }

    @include rm(1024) {
      left: 46px;
      top: 26px;
    }
  }

  @include rm(640){
    max-width: 280px;
  }

  @include rm(768){
    max-width: 330px;
    width: 100%;
  }

  @include rm(1024){
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 23px 46px 26px 46px;
  }
}

.catalog-item__title img {
  margin: 0 auto;
  max-width: 170px;
  width: 100%;

  @include rm(1024){
    margin: 0;
    height: 40px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}

.catalog-item__title span {
  display: none;

  @include rm(1024){
    display: block;
    text-align: left;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 10px;
    max-width: 300px;
  }
}
.catalog-item__visit {
  text-transform: uppercase;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  padding: 18px 24px;

  @include rm(1024){
    margin: 0;
    max-width: 210px;
  }
}

.catalog-item__bottom {
  @include rm(1024){
    width: 100%;
    max-width: 215px;
    margin-left: auto;
  }
}

.catalog__question {
  @include rm(1024){
    cursor: pointer;
  }
}

.catalog__tooltip {
  width: 20px;
  height: 20px;
}

.catalog-item--popular {
  position: relative;
  border: 1px solid $decorative-color;
  padding-top: 37px;
  box-shadow: $popular-item-shadow;

  &:before {
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    color: $accent-choise-card;
    font-size: 16px;
    font-weight: 700;
    left: 73px;
    top: -12px;
    
    @include rm(640) {
      left: 53px;
    }
    
    @include rm(768) {
      left: 73px;
    }
    
    @include rm(1024) {
      top: -4px;
      left: 63px;
    }
  }

  &:after {
    position: absolute;
    content: "Popular Choice";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 13px;
    padding-left: 20px;
    text-align: center;
    line-height: 1.2;
    font-size: 16px;
    width: 223px;
    height: 61px;
    background: center/contain no-repeat url($choice-bg);

    @include rm(1024) {
      left: 39px;
      transform: translateX(0);
      top: -13px;
    }
  }
}

.catalog-item--popular .catalog-item__title {
  margin-left: 0;
  width: 100%;
}

.catalog-item__advanteges--none {
  display: none;
  @include rm(1024){
    display: block;
  }
}

.catalog-item__hint {
  max-width: 323px;
  width: 100%;
  padding: 6px;
  text-align: left;
  color: $catalog-hint-color;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  background-color: $catalog-hint-bg;
  margin-bottom: 20px;
  
  @include rm(1024) {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background: center/contain no-repeat url($catalog-hint-icon);
  }
}

.catalog-item__hint--mob {
  display: flex;

  @include rm(1024) {
    display: none;
  }
}

.catalog-item__hint--desk {
  display: none;

  @include rm(1024) {
    display: flex;
  }
}

 @include catalog;
