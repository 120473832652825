.promo {
  overflow: hidden;
  background: $promo-bg;

  .container {
    @include rm(640){
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &_pages {
      @include rm(640){
        align-items: center;
        justify-content: center;
      }
      @include rm(1220){
        width: 100%;
      }
    }
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    color: $promo-text;
    margin-bottom: 12px;
    max-width: 800px;

    @include rm(360){
      font-size: 24px;
    }

    @include rm(640){
      font-size: 28px;
    }

    @include rm(768) {
      margin-bottom: 15px;
      justify-content: flex-start;
    }

    @include rm(1220){
      font-size: 32px;
    }

    &_pages {

      @include rm(768) {
        margin-bottom: 12px;
      }
    }
  }

  &__text {
    font-size: 14px;
    color: $promo-text;
    max-width: 800px;

    a {
      color: #fff;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include rm(1024){
      font-size: 16px;
    }

    &_pages {
      text-align: left;
      margin-bottom: 0;
      
      @include rm(480){
        text-align: left;
      }

      @include rm(768) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0 4px;
      }

      &-year {
        @include rm(768) {
          display: block;
          flex-wrap: unset;
        }
      }
    }
  }

  &__wrapper {

    @include rm(640){
      width: 100%;
      align-self: center;
      padding-bottom: 30px;
      padding-top: 30px;
      margin-right: 10px;
    }

    @include rm(1024){
      margin-right: 0px;
      max-width: 370px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    @include rm(1220){
      max-width: 460px;
    }

    &_pages {
      margin-top: 12px;

      @include rm(640){
        padding: 0;
        margin-right: 0;
        max-width: 1000px;
        margin: 30px 0 16px;
      }

      @include rm(1024){
        max-width: 1000px;
      }

      @include rm(1220){
        max-width: 1000px;
      }
    }
  }

  &__btn {
    max-width: 200px;
    margin: 30px 0 25px;
    background-color: $promo-btn-bg;
    color: $promo-btn-color;

    &:hover {
      background-color: $promo-btn-bg-hover;
      color: $promo-btn-color-hover;
    }

    @include rm(480){
      margin: 30px auto 25px;
    }

    @include rm(640){
      margin-bottom: 0;
      margin-left: 0;
      margin: 36px 0 0;
    }
  }

  &__break {
    display: none;

    @include rm(768){
      display: block;
    }

    &_pages {
      @include rm(768){
        display: none;
      }
    }
  }

  &__img-wrapper {
    max-width: 315px;
    width: 100%;
    position: relative;
    //padding-top: 30px;
    margin: 0 auto;



    @include rm(480){
      max-width: 350px;
    }
    @include rm(640){
      max-width: 330px;
      margin: 0;
    }
    @include rm(768){
      max-width: 385px;

    }
    @include rm(1024){
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 550px;
      //min-height: 400px;
    }

    @include rm(1220){
      max-width: 690px;
    }
  }

  &__img {
    max-width: 280px;
    width: 100%;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    @include rm(480){
      //max-width: 380px;
      max-width: 380px;
    }
    @include rm(640){
      max-width: 320px;
    }

    @include rm(768){
      max-width: 385px;
    }

    @include rm(1024){
      max-width: 530px;
    }
    @include rm(1220){
      max-width: 100%;
    }
  }

  &__img--small {
    max-width: 200px;
    padding-top: 30px;

    @include rm(480){
      max-width: 220px;
    }

    @include rm(640){
      margin-top: 20px;
      max-width: 230px;
    }

    @include rm(768){
      margin-top: 20px;
      max-width: 250px;
    }
    @include rm(1024){
      margin-top: 10px;
      max-width: 370px;
    }
  }

  &__circle {
    position: absolute;
    width: 320px;
    height: 320px;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: $promo-circle;
    border-radius: 50%;

    @include rm(480){
      width: 380px;
      height: 380px;
    }
    @include rm(640){
      width: 320px;
      height: 320px;
    }
    @include rm(768){
      width: 385px;
      height: 385px;
    }
    @include rm(1024){
      width: 550px;
      height: 550px;
      top: auto;
      bottom: -215px;
    }
    @include rm(1220){
      width: 690px;
      height: 690px;
      top: auto;
      bottom: -150px;
    }
  }

  &_product {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-top: 31px;
    padding-bottom: 22px;
    
    @include rm(640) {
      background-position: center;
      background-size: auto 100%;
    }
    
    @include rm(768) {
      min-height: 400px;
    }

    @include rm(1220) {
      background-size: 100%;
    }

    &-slavic {
      background-image: url($promo-product-bg-slavic-m);

      @include rm(640) {
        background-image: url($promo-product-bg-slavic);
      }
    }

    &-latin {
      background-image: url($promo-product-bg-latin-m);

      @include rm(640) {
        background-image: url($promo-product-bg-latin);
      }
    }

    &-asian {
      background-image: url($promo-product-bg-asian-m);

      @include rm(640) {
        background-image: url($promo-product-bg-asian);
      }
    }

    .promo__text,
    .promo__title {
      color: #fff;
    }
  }
}

.promo__logo {
  margin: 160px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(44deg) brightness(100%) contrast(102%);

  @include rm(640) {
    margin: 0 0 30px;
    width: 416px;
  }

  @include rm(1024) {
    margin-bottom: 0;
  }
}

.promo__logo img {
  width: 100%;
}


@include promo;